import { useNavigate } from "react-router-dom";
import { Card, CardBody, Button, Row, Col } from "reactstrap";

const PaymentCanceled = () => {
//   const navigate = useNavigate();

// //   const handleRetryClick = () => {
// //     navigate("/billing");
// //   };

  return (
    <>
      <h3>Payment Canceled</h3>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody className="custom-card-body">
              <h4>We're sorry to see your payment was canceled</h4>
              <hr />
              <p>
                If you need assistance or would like to try again, feel free to
                reach out or retry the payment process.
              </p>
              {/* <Button onClick={handleRetryClick} color="warning">
                Retry Payment
              </Button> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PaymentCanceled;
