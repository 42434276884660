import { useNavigate } from "react-router-dom";
import { Card, CardBody, Button, Row, Col } from "reactstrap";

const ThanksForJoining = () => {
//   const navigate = useNavigate();

//   const handleExploreClick = () => {
//     navigate("/");
//   };

  return (
    <>
      <h3>Welcome to HelperFox!</h3>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody className="custom-card-body">
              <h4>Thanks for Joining!</h4>
              <hr />
              <p>
                We're thrilled to have you with us. Explore our platform and
                discover the best home services tailored for you.
              </p>
              {/* <Button onClick={handleExploreClick} color="primary">
                Explore Now
              </Button> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ThanksForJoining;
